import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  standalone: true,
  imports: [RouterLink],
  template: `
    <!-- Mobile version -->
    <div
      class="flex w-full flex-col bg-legal bg-cover bg-center bg-no-repeat pb-24 pt-12 md:hidden"
    >
      <div class="gutup-container flex flex-col items-center space-y-12">
        <span class="font-vinasans text-3xl uppercase">Mentions legales</span>
        <span class="text-sm">
          <span class="font-ralewayBold">Dernière mise à jour</span>: 14 juillet
          2024
          <br />
          <br />
          Conformément à la réglementation suisse, et en particulier à la Loi
          sur les Télécommunications (LTC) et à la Loi fédérale contre la
          concurrence déloyale (LCD), nous mettons à disposition les
          informations suivantes concernant notre site Internet.
          <br />
          <span class="font-ralewayBold">1. Informations Générales</span>
          <br />
          • Nom de la marque : <span class="font-ralewayBold">Gutup</span>
          <br />
          <br />
          <span class="font-ralewayBold">2. Contact</span>
          <br />
          • Adresse e-mail : elise&commat;gutup.ch
          <br />
          <br />
          <span class="font-ralewayBold">3. Responsable de la publication</span>
          <br />
          • Nom du responsable : Elise Garcia
          <br />
          • Adresse e-mail du responsable : elise&commat;gutup.ch
          <br />
          <br />
          <span class="font-ralewayBold">4. Hébergement du site</span>
          <br />
          • Nom de l'hébergeur : Vercel Inc.
          <br />
          • Adresse de l'hébergeur : 440 N Barranca Avenue #4133 Covina, CA
          91723 United States
          <br />
          • Site web de l'hébergeur : https://vercel.com/
          <br />
          <br />
          <span class="font-ralewayBold">5. Propriété intellectuelle</span>
          <br />
          Le contenu de ce site, incluant, de façon non limitative, les textes,
          images, vidéos, graphismes, logos et icônes, est la propriété
          exclusive de
          <span class="font-ralewayBold">Gutup</span> ou de ses partenaires.
          Toute reproduction, représentation, modification, publication,
          adaptation de tout ou partie des éléments de ce site, quel que soit le
          moyen ou le procédé utilisé, est interdite, sauf autorisation écrite
          préalable de <span class="font-ralewayBold">Gutup</span>.
          <br />
          <br />
          <span class="font-ralewayBold"
            >6. Protection des données personnelles</span
          >
          <br />
          Pour toute information concernant le traitement de vos données
          personnelles, veuillez consulter notre
          <a href="" class="underline">Politique de Confidentialité</a>.
          <br />
          <br />
          <span class="font-ralewayBold">7. Limitation de responsabilité</span>
          <br />
          Les informations contenues sur ce site sont aussi précises que
          possible et le site est périodiquement remis à jour. Toutefois,
          <span class="font-ralewayBold">Gutup</span> ne peut garantir
          l'exactitude, la complétude et l'actualité des informations diffusées
          sur son site. <span class="font-ralewayBold">Gutup</span> décline
          toute responsabilité pour tout dommage direct ou indirect pouvant
          résulter de l'accès au site et de l'utilisation de ses contenus.
          <br />
          <br />
          <span class="font-ralewayBold">8. Liens hypertextes</span>
          <br />
          Ce site peut contenir des liens hypertextes vers d'autres sites
          internet. <span class="font-ralewayBold">Gutup</span> n'exerce aucun
          contrôle sur ces sites et décline toute responsabilité quant à leur
          contenu. L'accès à ces sites se fait sous la seule responsabilité de
          l'utilisateur.
          <br />
          <br />
          <span class="font-ralewayBold">9. Droit applicable</span>
          <br />
          Les présentes mentions légales sont régies par le droit suisse. Tout
          litige relatif à l'utilisation de ce site sera soumis à la compétence
          exclusive des tribunaux du siège social de
          <span class="font-ralewayBold">Gutup</span>.
        </span>
      </div>
    </div>

    <!-- Desktop version -->

    <div class="relative mx-auto hidden w-full max-w-[1154px] py-16 md:block">
      <div class="absolute inset-0 -z-10 bg-legal-desktop"></div>
      <div class="flex flex-col items-center space-y-12 px-12 py-12">
        <h1 class="font-vinasans text-3xl uppercase">Mentions legales</h1>
        <div class="max-w-full space-y-4 text-sm">
          <span class="font-ralewayBold">Dernière mise à jour</span>: 14 juillet
          2024
          <br />
          <br />
          Conformément à la réglementation suisse, et en particulier à la Loi
          sur les Télécommunications (LTC) et à la Loi fédérale contre la
          concurrence déloyale (LCD), nous mettons à disposition les
          informations suivantes concernant notre site Internet.
          <br />
          <span class="font-ralewayBold">1. Informations Générales</span>
          <br />
          • Nom de la marque : <span class="font-ralewayBold">Gutup</span>
          <br />
          <br />
          <span class="font-ralewayBold">2. Contact</span>
          <br />
          • Adresse e-mail : elise&commat;gutup.ch
          <br />
          <br />
          <span class="font-ralewayBold">3. Responsable de la publication</span>
          <br />
          • Nom du responsable : Elise Garcia
          <br />
          • Adresse e-mail du responsable : elise&commat;gutup.ch
          <br />
          <br />
          <span class="font-ralewayBold">4. Hébergement du site</span>
          <br />
          • Nom de l'hébergeur : Vercel Inc.
          <br />
          • Adresse de l'hébergeur : 440 N Barranca Avenue #4133 Covina, CA
          91723 United States
          <br />
          • Site web de l'hébergeur : https://vercel.com/
          <br />
          <br />
          <span class="font-ralewayBold">5. Propriété intellectuelle</span>
          <br />
          Le contenu de ce site, incluant, de façon non limitative, les textes,
          images, vidéos, graphismes, logos et icônes, est la propriété
          exclusive de
          <span class="font-ralewayBold">Gutup</span> ou de ses partenaires.
          Toute reproduction, représentation, modification, publication,
          adaptation de tout ou partie des éléments de ce site, quel que soit le
          moyen ou le procédé utilisé, est interdite, sauf autorisation écrite
          préalable de <span class="font-ralewayBold">Gutup</span>.
          <br />
          <br />
          <span class="font-ralewayBold"
            >6. Protection des données personnelles</span
          >
          <br />
          Pour toute information concernant le traitement de vos données
          personnelles, veuillez consulter notre
          <a href="" class="underline">Politique de Confidentialité</a>.
          <br />
          <br />
          <span class="font-ralewayBold">7. Limitation de responsabilité</span>
          <br />
          Les informations contenues sur ce site sont aussi précises que
          possible et le site est périodiquement remis à jour. Toutefois,
          <span class="font-ralewayBold">Gutup</span> ne peut garantir
          l'exactitude, la complétude et l'actualité des informations diffusées
          sur son site. <span class="font-ralewayBold">Gutup</span> décline
          toute responsabilité pour tout dommage direct ou indirect pouvant
          résulter de l'accès au site et de l'utilisation de ses contenus.
          <br />
          <br />
          <span class="font-ralewayBold">8. Liens hypertextes</span>
          <br />
          Ce site peut contenir des liens hypertextes vers d'autres sites
          internet. <span class="font-ralewayBold">Gutup</span> n'exerce aucun
          contrôle sur ces sites et décline toute responsabilité quant à leur
          contenu. L'accès à ces sites se fait sous la seule responsabilité de
          l'utilisateur.
          <br />
          <br />
          <span class="font-ralewayBold">9. Droit applicable</span>
          <br />
          Les présentes mentions légales sont régies par le droit suisse. Tout
          litige relatif à l'utilisation de ce site sera soumis à la compétence
          exclusive des tribunaux du siège social de
          <span class="font-ralewayBold">Gutup</span>.
        </div>
      </div>
    </div>
  `,
  styles: ``,
})
export class LegalMentionsComponent {}
