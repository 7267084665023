import { Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { BubbleCardComponent } from '../../../components/bubble-card.component';

@Component({
  selector: 'app-home-about',
  standalone: true,
  imports: [NgOptimizedImage, BubbleCardComponent],
  template: `
    <div class="flex flex-col space-y-8 md:flex-row md:space-x-12">
      <div class="relative space-y-6 text-left">
        <img
          class="absolute inset-0 -left-28 -top-20 hidden md:block"
          alt="fleche decorative qui pointe vers le texte"
          ngSrc="/gutup/home/gutup-arrow.svg"
          height="219"
          width="197"
        />
        <app-bubble-card [hasTitle]="true">
          <div class="flex flex-col md:px-12">
            <!--  Title part of the bubble -->
            <div class="mx-2 flex items-center justify-center pb-4 md:pb-0">
              <div>
                <!-- Mobile star-->
                <img
                  class="-ml-4 w-16 md:hidden md:pb-8"
                  alt="Etoile filante décorative"
                  ngSrc="/gutup/home/gutup-starfall.png"
                  height="448"
                  width="377"
                />
              </div>
              <div>
                <span class="font-moresugar text-base md:text-2xl"
                  >"Toute maladie commence dans l'intestin."</span
                >
                <br />
                <span class="font-cupcakes text-base md:text-3xl"
                  >Hippocrate, pere de la medecine</span
                >
              </div>
              <div>
                <img
                  class="hidden w-20 pb-8 md:block"
                  alt="Etoile filante décorative"
                  ngSrc="/gutup/home/gutup-starfall.png"
                  height="448"
                  width="377"
                />
              </div>
            </div>
            <!-- Description part -->
            <div>
              Chez GutUp, nous savons que chaque personne est unique. Que tu
              veuilles gérer une maladie chronique, perdre du poids ou
              simplement améliorer ta santé, tout commence par tes intestins.
              <span class="font-ralewayBold">Le microbiote intestinal</span>, ce
              monde fascinant de micro-organismes dans ton système digestif,
              joue un rôle crucial dans ton bien-être global.
              <br />
              <br />

              Pourquoi les intestins? Parce que la science montre que notre
              microbiote influence la digestion, le métabolisme, le système
              immunitaire et même notre humeur! Un déséquilibre peut entraîner
              des désagréments comme l’acné, les sautes d’humeur, le surpoids,
              voire des maladies chroniques comme le diabète, la dépression, les
              maladies cardiovasculaires ou l'obésité...
              <br />
              <br />

              <span class="font-ralewayBold"
                >Notre mission est de t'aider à restaurer et maintenir un
                microbiote sain.</span
              >
              Avec des solutions basées sur des preuves scientifiques, nous te
              proposons des podcasts inspirants, des conseils pratiques et des
              recettes délicieuses pour préserver ta santé!
              <br />
              <br />

              Chaque petit changement compte. En équilibrant ton microbiote, tu
              peux retrouver ton énergie, améliorer ta digestion, renforcer ton
              immunité et atteindre ton poids de forme.
              <br />
              <br />

              Avec GutUp, (re)prends le contrôle de ta santé...
              <span class="font-ralewayBold">commence par tes intestins!</span>
              <br />
              <br />
            </div>
          </div>
        </app-bubble-card>
      </div>
    </div>
  `,
})
export class HomeAboutComponent {}
