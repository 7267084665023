import { Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { MissionBubbleCardComponent } from './mission-bubble-card.component';

@Component({
  selector: 'app-constat-bubble',
  standalone: true,
  imports: [NgOptimizedImage, MissionBubbleCardComponent],
  template: `
    <div class="flex px-[10vw]">
      <app-mission-bubble-card>
        <h1 class="-mt-8 font-cupcakes text-4xl md:self-end md:pr-16">
          LE CONSTAT
        </h1>
        <div class="flex items-center space-x-4 px-4 py-4 md:px-8 md:pr-24">
          <div>
            <span>
              En Suisse, plus de 2,2 millions de personnes sont atteintes de
              maladies chroniques (diabète, hypertension, maladie
              cardiovasculaire, cancer, fatigue chronique, dépression, etc.).
              <br />
              <br />
              Mais la bonne nouvelle c’est que la plupart de ces maladies
              peuvent être prévenues par
              <span class="font-ralewayBold">nos habitudes alimentaires</span>!
            </span>
          </div>
          <!-- Mobile drapeau -->
          <img
            class="absolute -right-8 -top-20 w-28 md:hidden"
            alt="Main qui tient un drapeau Suisse"
            ngSrc="/gutup/mission/drapeau.png"
            height="710"
            width="711"
          />
          <!-- Desktop drapeau -->
          <img
            class="absolute -right-24 bottom-2 hidden w-60 md:block"
            alt="Main qui tient un drapeau Suisse"
            ngSrc="/gutup/mission/drapeau.png"
            height="710"
            width="711"
          />
        </div>
      </app-mission-bubble-card>
    </div>
    <!--    // TODO Alexy (click)-->
    <!--    <div class="flex justify-center pt-6 md:justify-end md:pr-12">-->
    <!--      <button class="gutup-button w-full md:w-auto">Dis m'en plus</button>-->
    <!--    </div>-->
  `,
})
export class ConstatBubbleComponent {}
