import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  standalone: true,
  imports: [RouterLink],
  template: ``,
  styles: ``,
})
export class NosSourcesComponent {}
