import { Component } from '@angular/core';
import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericEbookModalComponent } from './generic-ebook-modal.component';

@Component({
  selector: 'app-desktop-ebook-modal',
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass, NgOptimizedImage],
  template: `
    <div
      class="fixed inset-0 z-10 bg-black bg-opacity-50"
      (click)="closeModal()"
    ></div>
    <div
      class="fixed inset-0 z-20 hidden items-center justify-center md:flex"
      (click)="closeModal()"
    >
      <div
        class="relative min-h-[80vh] w-4/5 min-w-[80vw] rounded-lg bg-gutup-white py-6 shadow-lg"
        (click)="$event.stopPropagation()"
      >
        <a
          (click)="closeModal()"
          class="absolute right-2 top-2 z-10 block cursor-pointer p-4"
        >
          <img
            ngSrc="/gutup/ebook/cross.png"
            alt="Close"
            height="21"
            width="19"
          />
        </a>
        <div class="flex h-full">
          <div class="flex w-3/5 flex-col">
            <img
              ngSrc="/gutup/ebook/left-infos.png"
              alt="ebook"
              class="h-full w-full"
              width="2574"
              height="2372"
            />
          </div>
          <div class="relative flex w-2/5 flex-col">
            <img
              ngSrc="/gutup/ebook/right-infos.png"
              alt="background"
              class="absolute inset-0 h-full w-full"
              width="1920"
              height="2419"
            />
            <div class="relative flex h-2/3 w-2/3 flex-col justify-end px-4">
              <form
                [formGroup]="formEbook"
                (ngSubmit)="submitEbook()"
                class="space-y-2 pb-8 pl-[17%]"
              >
                <div class="w-full space-y-2 px-4">
                  <input
                    type="text"
                    formControlName="firstname"
                    id="firstname"
                    name="firstname"
                    placeholder="prénom"
                    class="w-full rounded-full py-1 text-center text-sm placeholder-gutup-orange shadow-lg"
                    [ngClass]="{
                      'border-red-500': showError(formEbook, 'firstname'),
                    }"
                  />
                  @if (showError(formEbook, 'firstname')) {
                    <div class="mt-1 text-xs text-red-500">Prénom requis</div>
                  }
                  <input
                    type="email"
                    formControlName="email"
                    id="email1"
                    name="email1"
                    placeholder="email"
                    class="w-full rounded-full py-1 text-center text-sm placeholder-gutup-orange shadow-lg"
                    [ngClass]="{
                      'border-red-500': showError(formEbook, 'email'),
                    }"
                  />
                  @if (showError(formEbook, 'email')) {
                    <div class="mt-1 text-xs text-red-500">
                      {{ getEmailErrorMessage(formEbook) }}
                    </div>
                  }
                </div>
                <button
                  type="submit"
                  class="w-full rounded-xl bg-gutup-orange p-2 font-vinasans text-lg text-white shadow-lg md:text-2xl 2xl:text-3xl"
                >
                  {{
                    isEbookSubmitting
                      ? 'Envoi en cours...'
                      : 'Je veux mon ebook!'
                  }}
                </button>
              </form>
              @if (submitErrorEbook) {
                <div class="mt-2 text-sm text-red-500">
                  Une erreur est survenue. Veuillez réessayer.
                </div>
              }
              @if (submitSuccessEbook) {
                <div class="mt-2 text-sm text-green-500">
                  Votre demande a été envoyée avec succès!
                </div>
              }
            </div>
            <div
              class="relative flex h-1/2 w-3/4 flex-col justify-end self-end"
            >
              <form
                [formGroup]="formPaperEbook"
                (ngSubmit)="submitPaperEbook()"
                class="space-y-2 pb-[8%] pr-[10%]"
              >
                <div class="w-full space-y-2 px-10">
                  <input
                    type="text"
                    formControlName="firstname"
                    id="firstname"
                    name="firstname"
                    placeholder="prénom"
                    class="h-[10%] w-full rounded-full py-2 text-center text-sm placeholder-gutup-orange shadow-lg"
                    [ngClass]="{
                      'border-red-500': showError(formPaperEbook, 'firstname'),
                    }"
                  />
                  @if (showError(formPaperEbook, 'firstname')) {
                    <div class="mt-1 text-xs text-red-500">Prénom requis</div>
                  }
                  <input
                    type="email"
                    formControlName="email"
                    id="email"
                    name="email"
                    placeholder="email"
                    class="h-[10%] w-full rounded-full py-2 text-center text-sm placeholder-gutup-orange shadow-lg"
                    [ngClass]="{
                      'border-red-500': showError(formPaperEbook, 'email'),
                    }"
                  />
                  @if (showError(formPaperEbook, 'email')) {
                    <div class="mt-1 text-xs text-red-500">
                      {{ getEmailErrorMessage(formPaperEbook) }}
                    </div>
                  }
                </div>
                <button
                  type="submit"
                  class="w-full rounded-xl bg-gutup-orange p-2 font-vinasans text-lg text-white shadow-lg md:text-2xl 2xl:text-3xl"
                >
                  {{
                    isEbookPaperSubmitting
                      ? 'Envoi en cours...'
                      : 'Je precommande mon livre!'
                  }}
                </button>
              </form>
              @if (submitErrorPaperEbook) {
                <div class="mt-2 text-sm text-red-500">
                  Une erreur est survenue. Veuillez réessayer.
                </div>
              }
              @if (submitSuccessPaperEbook) {
                <div class="mt-2 text-sm text-green-500">
                  Votre demande a été envoyée avec succès!
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class DesktopEbookModalComponent extends GenericEbookModalComponent {}
