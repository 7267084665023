import { Component, inject, isDevMode } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { AwsSesService } from '../../services/aws-ses.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-contact',
  standalone: true,
  styles: [
    `
      input,
      textarea {
        @apply rounded-xl border border-black;
      }

      .error-message {
        @apply mt-1 text-sm text-gutup-dark;
      }
    `,
  ],
  template: `
    <div
      class="flex flex-col items-center space-y-12 bg-contact bg-cover bg-no-repeat pb-52 md:bg-none md:pb-24"
    >
      <div class="gutup-container flex flex-col items-center">
        <div>
          <img src="/gutup/contact/contact-title.svg" alt="C'est parti" />
        </div>
        <div
          class="flex flex-col items-center space-y-12 md:min-h-[660px] md:min-w-[814px] md:bg-contact-desktop md:bg-cover md:bg-no-repeat"
        >
          <div class="flex w-full items-center justify-center pt-20">
            <form
              [formGroup]="contactForm"
              (ngSubmit)="onSubmit()"
              class="grid w-full max-w-xl grid-cols-1 gap-4 px-4 md:grid-cols-2 md:px-0"
            >
              <div class="col-span-1 flex flex-col">
                <label for="firstname" class="mb-2">Prénom *</label>
                <input
                  formControlName="firstname"
                  type="text"
                  id="firstname"
                  name="firstname"
                  class="w-full rounded border px-4 py-2"
                  [ngClass]="{ 'border-gutup-dark': showError('firstname') }"
                />
                @if (showError('firstname')) {
                  <div class="error-message">Ce champ est requis</div>
                }
              </div>
              <div class="col-span-1 flex flex-col">
                <label for="lastname" class="mb-2">Nom *</label>
                <input
                  formControlName="lastname"
                  type="text"
                  id="lastname"
                  name="lastname"
                  class="w-full rounded border px-4 py-2"
                  [ngClass]="{ 'border-gutup-dark': showError('lastname') }"
                />
                @if (showError('lastname')) {
                  <div class="error-message">Ce champ est requis</div>
                }
              </div>
              <div class="col-span-1 flex flex-col">
                <label for="phone" class="mb-2">Téléphone *</label>
                <input
                  formControlName="phone"
                  placeholder="076 00 00 00 00"
                  type="tel"
                  id="phone"
                  name="phone"
                  class="w-full rounded border px-4 py-2"
                  [ngClass]="{ 'border-gutup-dark': showError('phone') }"
                  (input)="onPhoneInput($event)"
                />
                @if (showError('phone')) {
                  <div class="error-message">
                    {{ getPhoneErrorMessage() }}
                  </div>
                }
              </div>
              <div class="col-span-1 flex flex-col">
                <label for="email" class="mb-2">Email *</label>
                <input
                  formControlName="email"
                  placeholder="julien.dupont@exemple.ch"
                  type="email"
                  id="email"
                  name="email"
                  class="w-full rounded border px-4 py-2"
                  [ngClass]="{ 'border-gutup-dark': showError('email') }"
                />
                @if (showError('email')) {
                  <div class="error-message">
                    {{ getEmailErrorMessage() }}
                  </div>
                }
              </div>
              <div class="col-span-1 flex flex-col md:col-span-2">
                <label for="message" class="mb-2">Message *</label>
                <textarea
                  formControlName="message"
                  placeholder="Dis-nous en plus ici!"
                  id="message"
                  name="message"
                  class="h-32 w-full rounded border px-4 py-2"
                  [ngClass]="{ 'border-gutup-dark': showError('message') }"
                ></textarea>
                @if (showError('message')) {
                  <div class="error-message">Ce champ est requis</div>
                }
              </div>
              <!--              <div class="col-span-1 flex items-start space-x-2 md:col-span-2">-->
              <!--                <input-->
              <!--                  type="checkbox"-->
              <!--                  formControlName="consent"-->
              <!--                  id="consent"-->
              <!--                  class="mt-1"-->
              <!--                />-->
              <!--                <label for="consent">-->
              <!--                  Je consens au traitement de mes données personnelles <br />-->
              <!--                  conformément à la-->
              <!--                  <span class="underline">Politique de Confidentialité</span> de-->
              <!--                  GutUp.*-->
              <!--                </label>-->
              <!--              </div>-->
              <!--              @if (showError('consent')) {-->
              <!--                <div class="error-message col-span-1 md:col-span-2">-->
              <!--                  Vous devez accepter la politique de confidentialité pour-->
              <!--                  continuer-->
              <!--                </div>-->
              <!--              }-->
              <span class="col-span-1 text-sm md:col-span-2"
                >(*) indique les champs requis</span
              >
              <div class="col-span-1 flex justify-center md:col-span-2">
                <button
                  class="gutup-button w-40"
                  type="submit"
                  [disabled]="submissionStatus === 'sending'"
                >
                  {{
                    submissionStatus === 'sending'
                      ? 'Envoi en cours...'
                      : 'Envoyer'
                  }}
                </button>
                @if (isDevMode) {
                  <button
                    type="button"
                    class="gutup-button w-40"
                    (click)="fillFormWithCorrectValues()"
                  >
                    DEV: Remplir le formulaire
                  </button>
                }
              </div>
              @if (submissionStatus === 'success') {
                <div
                  class="col-span-1 mt-4 text-center text-green-600 md:col-span-2"
                >
                  Votre message a été envoyé avec succès !
                </div>
              }
              @if (submissionStatus === 'error') {
                <div
                  class="col-span-1 mt-4 text-center text-red-600 md:col-span-2"
                >
                  Une erreur est survenue lors de l'envoi. Veuillez réessayer.
                </div>
              }
            </form>
          </div>
        </div>
      </div>
    </div>
  `,
  imports: [ReactiveFormsModule, CommonModule],
})
export class ContactComponent {
  contactForm: FormGroup;
  formSubmitted = false;
  submissionStatus: 'initial' | 'sending' | 'success' | 'error' = 'initial';
  isDevMode = isDevMode();

  #fb = inject(FormBuilder);
  #sesService = inject(AwsSesService);

  constructor() {
    this.contactForm = this.#fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      phone: ['', [Validators.required, this.phoneValidator]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
      // consent: [false, Validators.requiredTrue],
    });
  }

  showError(fieldName: string): boolean {
    const control = this.contactForm.get(fieldName);
    return (
      (control?.invalid && (control.touched || this.formSubmitted)) ?? false
    );
  }

  getEmailErrorMessage(): string {
    const emailControl = this.contactForm.get('email');
    if (emailControl?.hasError('required')) {
      return 'Ce champ est requis';
    }
    return emailControl?.hasError('email')
      ? `L'email doit être au format aaa@bbb.ccc`
      : '';
  }

  getPhoneErrorMessage(): string {
    const phoneControl = this.contactForm.get('phone');
    if (phoneControl?.hasError('required')) {
      return 'Ce champ est requis';
    }
    if (phoneControl?.hasError('invalidFormat')) {
      return 'Numéro de téléphone invalide';
    }
    if (phoneControl?.hasError('tooShort')) {
      return 'Le numéro doit contenir au moins 10 chiffres';
    }
    if (phoneControl?.hasError('tooManyPlus')) {
      return 'Le numéro ne peut contenir qu\'un seul caractère "+"';
    }
    return '';
  }

  phoneValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return null;
    }

    // Remove all spaces from the input
    const phoneNumber = value.replace(/\s/g, '');

    // Count digits and '+' characters
    const digitCount = (phoneNumber.match(/\d/g) || []).length;
    const plusCount = (phoneNumber.match(/\+/g) || []).length;

    // Check for invalid characters
    if (!/^[+\d]+$/.test(phoneNumber)) {
      return { invalidFormat: true };
    }

    // Check for minimum length
    if (digitCount < 10) {
      return { tooShort: true };
    }

    // Check for too many '+' characters
    if (plusCount > 1) {
      return { tooManyPlus: true };
    }

    return null;
  }

  onPhoneInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^\d+\s]/g, '');
    this.contactForm.patchValue({ phone: input.value });
  }

  fillFormWithCorrectValues(): void {
    this.contactForm.patchValue({
      firstname: 'John',
      lastname: 'Doe',
      phone: '076 123 45 67',
      email: 'john.doe@example.com',
      message: 'This is a test message.',
      // consent: true,
    });
  }

  async onSubmit(): Promise<void> {
    this.formSubmitted = true;
    this.contactForm.markAllAsTouched();

    if (this.contactForm.valid) {
      try {
        this.submissionStatus = 'sending';
        await this.#sesService.sendEmail(this.contactForm.value);
        this.submissionStatus = 'success';
        this.contactForm.reset();
        this.formSubmitted = false;
      } catch (error) {
        console.error('Error sending email:', error);
        this.submissionStatus = 'error';
      }
    }
  }
}
