import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { BubbleCardComponent } from '../bubble-card.component';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-gutup-elise-about',
  standalone: true,
  imports: [RouterLink, BubbleCardComponent, NgOptimizedImage],
  template: `
    <div class="flex flex-col space-y-12 md:flex-row">
      <div class="items-center self-center md:w-1/2">
        <div class="hidden md:flex md:flex-col">
          <div class="flex flex-col items-center justify-center">
            <img
              [ngSrc]="speaker.imageUrl"
              [alt]="speaker.name"
              width="500"
              height="500"
            />
            @if (speaker.buttonText) {
              <a [href]="speaker.buttonLink" target="_blank">
                <button class="gutup-button w-56">
                  {{ speaker.buttonText }}
                </button>
              </a>
            }
          </div>
        </div>
      </div>
      <div class="space-y-2 text-left md:w-1/2">
        <div class="flex justify-center md:hidden">
          <img [src]="speaker.imageUrl" [alt]="speaker.name" />
        </div>
        <div class="pb-4 text-center font-cupcakes text-3xl md:text-5xl">
          Hello, <br />
          Moi c'est Elise.
        </div>
        <div class="max-w-[1154px]">
          <app-bubble-card>
            <div [innerHTML]="speaker.bio"></div>
          </app-bubble-card>
        </div>
        <div class="flex justify-center pt-2 md:hidden">
          @if (speaker.buttonText) {
            <a [href]="speaker.buttonLink" target="_blank" class="w-full">
              <button class="gutup-button w-full">
                {{ speaker.buttonText }}
              </button>
            </a>
          }
        </div>
      </div>
    </div>
  `,
})
export class GutupEliseAboutComponent {
  speaker = {
    name: 'Elise Garcia',
    bio: `
    <div>
      Je suis la fondatrice de GutUp.
      <br><br>

      En tant que véritable épicurienne, j’ai dû  rééquilibrer mon alimentation car ma santé en dépendait.
      <br><br>

      Résultat? Finis les ballonnements, les problèmes de peau, les kilos en trop, les maladies à répétition et la dépression... Je ne me suis jamais sentie autant en forme physique et mentale.
      <br><br>

      Forte de ce constat, j’ai décidé de m’entourer de professionnels de la santé et de l'alimentation pour te partager ces découvertes!
      <br><br>

      Ensemble, nous te proposons des podcasts, des recettes et des conseils pour comprendre ton corps et lui donner le carburant dont il a besoin.
      <br><br>

      Mon objectif avec GutUp?
      <br>
      Que tu préserves ta santé, tout en te régalant, pour croquer la vie à pleines dents.
      Je me réjouis de te compter dans la communauté GutUp!
      <br><br>
      <div class="flex content-center items-center">
        <span class="font-ralewayBold">Prends soin de toi.</span>
        <img class="pl-4" alt="Signature d'Elise" src="/gutup/podcast/podcast1/elise-signature.svg"
      </div>
    </div>
    `,
    imageUrl: '/gutup/podcast/podcast1/podcast-portrait-elise.png',
    buttonText: 'Suis-moi sur Instagram',
    buttonLink: 'https://www.instagram.com/gutup_foodie/',
  };
}
