import { Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { MissionBubbleCardComponent } from './mission-bubble-card.component';

@Component({
  selector: 'app-mission-bubble',
  standalone: true,
  imports: [NgOptimizedImage, MissionBubbleCardComponent],
  template: `
    <div class="flex px-[10vw]">
      <app-mission-bubble-card>
        <h1 class="-mt-8 self-start font-cupcakes text-4xl md:self-auto">
          LA MISSION
        </h1>
        <div class="flex items-center space-x-4 py-2 md:px-6 md:pr-24">
          <img
            class="absolute -right-2 bottom-0 hidden w-16 md:-right-4 md:block md:w-32"
            alt="Un bras musclé"
            ngSrc="/gutup/mission/arm.png"
            height="656"
            width="498"
          />
          <img
            class="absolute -right-2 -top-6 w-16 md:-right-4 md:hidden md:w-32"
            alt="Un bras musclé"
            ngSrc="/gutup/mission/arm.png"
            height="656"
            width="498"
          />
          <span class="py-4">
            Chez GutUp, notre mission est de t'accompagner vers un bien-être
            physique et mental grâce à une alimentation saine, essentielle pour
            un microbiote intestinal en pleine santé. Nous sommes convaincus que
            la santé commence dans l'assiette et que des intestins en pleine
            forme sont la clé d'une vie épanouie.
            <br />
            <br />
            En nous basant sur des faits scientifiquement prouvés et nos
            partenaires professionnels de santé, nous te proposons des recettes
            simples, des conseils pratiques et des podcasts inspirants. Notre
            objectif est d'<span class="font-ralewayBold"
              >améliorer ta digestion</span
            >, de
            <span class="font-ralewayBold"
              >renforcer ton système immunitaire</span
            >
            et d'<span class="font-ralewayBold"
              >augmenter ton niveau d'énergie</span
            >.
          </span>
        </div>
      </app-mission-bubble-card>
    </div>
  `,
})
export class MissionBubbleComponent {}
