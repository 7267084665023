import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { RoutingService } from '../../../services/routing.service';
import { GutupPodcastListComponent } from '../gutup-podcast-list/gutup-podcast-list.component';
import { PodcastDetail } from './podcast.detail';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { map, Observable } from 'rxjs';
import { BubbleCardComponent } from '../../../components/bubble-card.component';
import { PodcastHeaderComponent } from './podcast-header.component';
import { PodcastSpeakerComponent } from './podcast-speaker.component';
import { PodcastListComponent } from './podcast-list.component';
import { PodcastSharedLinksComponent } from './podcast-links.component';
import { PodcastDescriptionComponent } from './podcast-description.component';
import NewsletterFormComponent from '../../../components/newsletter-form.component';

@Component({
  selector: 'app-podcast-detail',
  standalone: true,
  imports: [
    RouterLink,
    GutupPodcastListComponent,
    AsyncPipe,
    NgOptimizedImage,
    BubbleCardComponent,
    PodcastHeaderComponent,
    PodcastDescriptionComponent,
    PodcastSharedLinksComponent,
    PodcastSpeakerComponent,
    PodcastListComponent,
    NewsletterFormComponent,
  ],
  template: `
    @if (podcastDetail$ | async; as podcastDetail) {
      <div class="gutup-container max-w-screen-2xl">
        <div class="space-y-6 md:space-y-24">
          <div>
            <app-podcast-header [podcastDetail]="podcastDetail" />
          </div>

          <div class="flex justify-center">
            <app-podcast-description
              [description]="podcastDetail.description"
            />
          </div>

          <div>
            <app-podcast-shared-links
              [spotifyLink]="podcastDetail.spotifyLink"
              [youtubeLink]="podcastDetail.youtubeLink"
            />
          </div>

          <div class="flex justify-center">
            <app-podcast-speaker [speaker]="podcastDetail.speaker" />
          </div>

          <div class="py-12">
            <app-bubble-card
              [hasTitle]="true"
              [backgroundName]="'bg-green-bubble'"
            >
              <app-newsletter-hubspot-form class="w-full" />
            </app-bubble-card>
          </div>

          <div>
            <app-podcast-list (navigateToPodcast)="navigateToPodcast()" />
          </div>
        </div>
      </div>
    }
  `,
})
export class PodcastDetailComponent implements OnInit {
  podcastDetail$!: Observable<PodcastDetail>;

  #routingService = inject(RoutingService);
  #route = inject(ActivatedRoute);

  navigateToPodcast() {
    this.#routingService.navigate(['/podcast'], { scrollTop: true });
  }

  ngOnInit() {
    this.podcastDetail$ = this.#route.data.pipe(
      map((data) => data['podcastDetail']),
    );
  }
}
