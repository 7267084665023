{
  "aliments": [
    {
      "bienfaits": "<p>L'airelle rouge est un concentr&eacute; de bienfaits : elle est riche en antioxydants, comme les flavono&iuml;des et la vitamine C, qui boostent ton syst&egrave;me immunitaire. Elle aide aussi &agrave; pr&eacute;venir les infections urinaires, &agrave; r&eacute;duire le cholest&eacute;rol et &agrave; soutenir la sant&eacute; cardiovasculaire.</p>",
      "collectionId": "wir52u8tj9y3rnj",
      "collectionName": "aliments",
      "comment_cuisiner": "<p>Pour maximiser les bienfaits, consomme les airelles rouges crues, en salade, ou mix&eacute;es en smoothie. Si tu veux les cuire, fais-le &agrave; feu doux et rapidement pour conserver un maximum de vitamines et d'antioxydants.</p>",
      "created": "2024-08-21 15:12:05.832Z",
      "disponibilite": "<p>Les airelles rouges sont disponibles de septembre &agrave; novembre en Suisse. Toutefois, tu peux les trouver surgel&eacute;es ou en conserve tout au long de l'ann&eacute;e.</p>",
      "fait_nutritionnel": "<p>Les airelles peuvent am&eacute;liorer la sant&eacute; vasculaire gr&acirc;ce &agrave; leur haute teneur en polyph&eacute;nols. Elles ont aussi des effets positifs sur la gestion du cholest&eacute;rol.</p>",
      "id": "hghermmnx7ljhsy",
      "intro": "Découvre tous les secrets de l'airelle rouge, de ses bienfaits à ses méthodes de consommation pour optimiser ta santé avec des conseils pratiques et des faits scientifiques.",
      "name": "L'airelle rouge",
      "picture": "",
      "recipes_ideas": [],
      "selection": "<p>Choisis des airelles rouges bien fermes, d'un rouge intense et brillant, sans t&acirc;ches ni signes de moisissure. La fra&icirc;cheur se v&eacute;rifie aussi par leur texture, qui doit &ecirc;tre l&eacute;g&egrave;rement &eacute;lastique.</p>",
      "sources": "<p>- <a target=\"_new\" rel=\"noopener\">Sant&eacute; et Nutrition - CHUV Lausanne</a> <br>- <em>Journal of Nutrition</em> <a target=\"_new\" rel=\"noopener\">lien</a> <br>- <a target=\"_new\" rel=\"noopener\">Swiss Food Research</a></p>",
      "updated": "2024-08-21 15:12:05.832Z",
      "visible": false
    },
    {
      "bienfaits": "<div>\r\n<p>L'abricot est une excellente source de vitamine A, essentielle pour la sant&eacute; des yeux et la peau. Il contient &eacute;galement des fibres qui favorisent la digestion et des antioxydants qui aident &agrave; pr&eacute;venir les maladies cardiovasculaires et certains cancers. Les abricots sont &eacute;galement riches en potassium, ce qui est b&eacute;n&eacute;fique pour la sant&eacute; cardiaque.</p>\r\n</div>",
      "collectionId": "wir52u8tj9y3rnj",
      "collectionName": "aliments",
      "comment_cuisiner": "<div>\r\n<p>Pour pr&eacute;server les nutriments, il est pr&eacute;f&eacute;rable de consommer les abricots frais ou s&eacute;ch&eacute;s. Si tu souhaites les cuisiner, opte pour une cuisson rapide &agrave; la vapeur ou au four &agrave; basse temp&eacute;rature. Les abricots peuvent &eacute;galement &ecirc;tre ajout&eacute;s crus aux salades ou utilis&eacute;s dans des smoothies.</p>\r\n</div>",
      "created": "2024-08-08 12:57:37.650Z",
      "disponibilite": "<div>\r\n<p>Les abricots sont disponibles en Suisse principalement durant les mois d'&eacute;t&eacute;, de juin &agrave; ao&ucirc;t. C'est durant cette p&eacute;riode qu'ils sont les plus savoureux et les plus nutritifs.</p>\r\n</div>",
      "fait_nutritionnel": "<div>\r\n<p>Une &eacute;tude a montr&eacute; que les abricots s&eacute;ch&eacute;s conservent une grande partie de leurs nutriments, notamment les fibres et les antioxydants, ce qui en fait un excellent choix pour les collations!</p>\r\n</div>",
      "id": "gl1u809xl7j5xrf",
      "intro": "Découvre tous les secrets de l'abricot, de ses bienfaits à ses méthodes de cuisson, et optimise ta santé avec des conseils pratiques et des faits scientifiques.",
      "name": "L'abricot",
      "picture": "aliments_publication_instagram_2_LkzWn9dxVt.png",
      "recipes_ideas": [],
      "selection": "<div>\r\n<p>Choisis des abricots fermes mais l&eacute;g&egrave;rement souples au toucher, avec une couleur orange uniforme. &Eacute;vite ceux qui pr&eacute;sentent des t&acirc;ches brunes ou des rides. Une l&eacute;g&egrave;re odeur sucr&eacute;e indique souvent que l'abricot est m&ucirc;r.</p>\r\n</div>",
      "sources": "<p><a href=\"https://www.inrae.fr/\">INRAE</a> - Institut national de la recherche agronomique et environnementale</p>\r\n<p><a href=\"https://www.anses.fr/fr\">ANSES</a> - Agence nationale de s&eacute;curit&eacute; sanitaire de l'alimentation, de l'environnement et du travail&nbsp;</p>\r\n<p><a href=\"https://www.aprifel.com/fr/fiche-nutritionnelle/\">Aprifel</a><!-- notionvc: 07b6d199-7ee9-48c0-b760-f5aec09523e3 --></p>",
      "updated": "2024-08-21 13:42:47.609Z",
      "visible": false
    },
    {
      "bienfaits": "",
      "collectionId": "wir52u8tj9y3rnj",
      "collectionName": "aliments",
      "comment_cuisiner": "",
      "created": "2024-08-02 12:10:16.554Z",
      "disponibilite": "",
      "fait_nutritionnel": "",
      "id": "x3lrzvlrw7s1bf5",
      "intro": "",
      "name": "carotte",
      "picture": "whats_app_image_2024_07_30_at_14_17_xy9gSm72iu.50.jpeg",
      "recipes_ideas": [],
      "selection": "",
      "sources": "",
      "updated": "2024-08-08 14:24:35.150Z",
      "visible": true
    },
    {
      "bienfaits": "",
      "collectionId": "wir52u8tj9y3rnj",
      "collectionName": "aliments",
      "comment_cuisiner": "",
      "created": "2024-07-29 20:59:33.114Z",
      "disponibilite": "",
      "fait_nutritionnel": "",
      "id": "vxx7618bs47nknh",
      "intro": "",
      "name": "patate",
      "picture": "",
      "recipes_ideas": [],
      "selection": "",
      "sources": "",
      "updated": "2024-08-08 14:24:31.709Z",
      "visible": true
    },
    {
      "bienfaits": "",
      "collectionId": "wir52u8tj9y3rnj",
      "collectionName": "aliments",
      "comment_cuisiner": "",
      "created": "2024-07-29 20:55:44.342Z",
      "disponibilite": "",
      "fait_nutritionnel": "",
      "id": "vxq3k9gb1k1pdqv",
      "intro": "",
      "name": "courgette",
      "picture": "",
      "recipes_ideas": [],
      "selection": "",
      "sources": "",
      "updated": "2024-08-08 14:24:21.158Z",
      "visible": true
    }
  ],
  "recettes": [
    {
      "Elise_touch": "<div>\r\n<p>Mon combo pr&eacute;f&eacute;r&eacute; c&rsquo;est d&eacute;finitivement graines de chia, lait de cajou maison, cacao en poudre, banane, beurre de noisettes. Le plein de bonnes fibres pour mon microbiote d&egrave;s le matin. Et &eacute;videmment accompagn&eacute; d&rsquo;un matcha latt&eacute;. Prends soin de toi!</p>\r\n</div>",
      "avant_toute_chose": "<div>\r\n<p>Si tu cherches une recette de pudding de chia simple, rapide, sans lactose, sans gluten, riche en prot&eacute;ines, &agrave; faible indice glyc&eacute;mique et v&eacute;g&eacute;tarienne, tu es au bon endroit. Le pudding de chia est top pour un petit-d&eacute;jeuner ou un dessert sain et d&eacute;licieux. Suis ce guide pour pr&eacute;parer le pudding de chia parfait!</p>\r\n</div>",
      "bienfaits": "<div>\r\n<p>Les graines de chia sont pleines de fibres, de prot&eacute;ines, d'om&eacute;ga-3, de vitamines et de min&eacute;raux. Elles sont un vrai tr&eacute;sor nutritionnel. Elles aident &agrave; am&eacute;liorer la digestion, &agrave; r&eacute;guler la glyc&eacute;mie et &agrave; te donner de l'&eacute;nergie tout au long de la journ&eacute;e. Les graines de chia sont une excellente source de prot&eacute;ines, parfaites pour les v&eacute;g&eacute;tariens ou ceux qui veulent augmenter leur apport en prot&eacute;ines.</p>\r\n</div>",
      "collectionId": "9ix6y3v9rzsuxy5",
      "collectionName": "recettes",
      "created": "2024-08-29 09:22:12.042Z",
      "faq": null,
      "id": "2bhoxsnrullp4c8",
      "ingredients": [],
      "nos_conseils": "<div>\r\n<p>Le choix du lait v&eacute;g&eacute;tal peut affecter le go&ucirc;t et la texture du pudding. Le lait d'amande, le lait de coco ou le lait de noisette sont d'excellents choix. Lis bien les &eacute;tiquettes et choisis un lait <strong>sans sucres ajout&eacute;s </strong>qui n&rsquo;en fera plus un petit-d&eacute;jeuner &agrave; IG bas. Pour &eacute;viter que les graines de chia ne forment des grumeaux, remue le m&eacute;lange apr&egrave;s 10 minutes de repos, puis laisse reposer &agrave; nouveau.</p>\r\n</div>",
      "recette": "<div>\r\n<p><strong>Pr&eacute;paration des ingr&eacute;dients (la veille)</strong> <br>Rassemble tous les ingr&eacute;dients n&eacute;cessaires pour ton pudding de chia. Assure-toi que le lait v&eacute;g&eacute;tal est bien m&eacute;lang&eacute; avant utilisation. </p>\r\n<p><strong>M&eacute;lange des ingr&eacute;dients&nbsp;</strong><br>Dans un bol, m&eacute;lange les graines de chia avec le lait d'amande et l'extrait de vanille. Remue bien pour &eacute;viter que les graines de chia ne collent ensemble.</p>\r\n<div>\r\n<p><strong>Temps de repos</strong> <br>Couvre le bol et laisse reposer au r&eacute;frig&eacute;rateur pendant au moins 2 heures, ou id&eacute;alement toute la nuit. Les graines de chia vont absorber le liquide et cr&eacute;er une texture de pudding. <br><br><strong>Ajout des garnitures</strong> <br>Le matin, sors le pudding et ajoute des fruits frais, des noix, ou des graines selon tes go&ucirc;ts. Les garnitures peuvent inclure des baies, des tranches de banane, des amandes, de la noix de coco r&acirc;p&eacute;e, ou des graines de lin.</p>\r\n</div>\r\n</div>",
      "recipe_idea": "",
      "subtitle": "Préparation - 5 minutes",
      "title": "Pudding de chia inratable",
      "updated": "2024-08-29 09:22:12.042Z"
    },
    {
      "Elise_touch": "",
      "avant_toute_chose": "",
      "bienfaits": "",
      "collectionId": "9ix6y3v9rzsuxy5",
      "collectionName": "recettes",
      "created": "2024-07-29 20:55:58.726Z",
      "faq": {
        "faq": [
          {
            "question": "Salut?",
            "reponse": "salut"
          }
        ]
      },
      "id": "ra9gttlyesefv16",
      "ingredients": ["vxq3k9gb1k1pdqv", "vxx7618bs47nknh"],
      "nos_conseils": "",
      "recette": "",
      "recipe_idea": "",
      "subtitle": "",
      "title": "Salade de courgette",
      "updated": "2024-08-08 14:35:08.658Z"
    }
  ],
  "profiles": [
    {
      "address_link": "",
      "address_text": "",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-10-05 15:40:04.491Z",
      "description": "<div>Pharmacienne nutritionniste spécialisée en micronutrition, j'aide mes patient·es à mieux comprendre leur organisme et ses réactions afin de répondre à leur problématique du type fatigue chronique (troubles du sommeil, gestion du stress, etc.), troubles digestifs (ballonnements, constipation, diarrhées), ou accompagnement de perte de poids.<br><br>Avec bienveillance, je vous propose des solutions personnalisées pour corriger les déséquilibres avec l'aide de l'alimentation et différents outils (les compléments alimentaires, la phytothérapie, l'action sur le microbiote). Ensemble, nous travaillons à votre rythme pour retrouver une alimentation et une hygiène de vie équilibrée qui vous permettront d'améliorer votre qualité de vie.</div>",
      "email": "",
      "first_name": "Melanie",
      "hidden": false,
      "id": "slkfbi0mpar6b5h",
      "last_name": "Hennequin Levrier",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Pharmacienne et Nutritionniste",
      "updated": "2024-10-05 15:40:04.491Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-10-05 15:40:03.219Z",
      "description": "<div>Diplômée en 2008 de la Haute École de Santé (HES), je suis Diététicienne, membre de l'Association Suisse des Diététicien(ne)s diplômé(e)s (ASDD) au Centre Métabolique Nutrition et Mouvement à Carouge.<br><br>Mon approche est globale et personnalisée. Je prends en compte l'environnement familial, la culture, les expériences de vie, le cadre de travail, et les sensations digestives de mes patients pour leur offrir des conseils adaptés et durables. En utilisant des techniques d'entretien motivationnel, je soutiens les motivations de chacun pour les aider à persévérer. Mon approche cognitivo-comportementale permet d'identifier et de travailler sur les déclencheurs des comportements alimentaires. Je crois fermement que l'acte alimentaire reflète l'éducation, les principes, la culture et les expériences de vie de chacun.<br><br>Passionnée de cuisine italienne, j'adore partager des repas conviviaux avec mes proches. Je pratique également la course à pied et le fitness. Je peux mener des consultations en français et en anglais.<br><br></div>",
      "email": "",
      "first_name": "Deborah",
      "hidden": false,
      "id": "spbcelqxbptv12g",
      "last_name": "Haesslein",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Diététicienne ASDD",
      "updated": "2024-10-05 15:40:03.219Z",
      "website": ""
    },
    {
      "address_link": "",
      "address_text": "",
      "buttonLink": "https://www.instagram.com/gutup_foodie/",
      "buttonText": "Suis-moi sur Instagram",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-10-05 15:40:01.980Z",
      "description": "<div>Je suis la fondatrice de GutUp et je me réjouis de te compter dans la communauté!<br><br>Si tu veux en apprendre plus sur mon quotidien, suis mes aventures sur Instagram <strong>@gutup_foodie</strong>.<br><br><div class=\"flex content-center items-center\">Prends soin de toi.<img class=\"pl-4\" alt=\"Signature d'Elise\" src=\"/gutup/podcast/podcast1/elise-signature.svg\"></div></div>",
      "email": "",
      "first_name": "Elise",
      "hidden": false,
      "id": "bmx5gbv9dbaapwo",
      "last_name": "Garcia",
      "phone_number": "",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "Épicurienne",
      "updated": "2024-10-05 15:40:01.980Z",
      "website": ""
    },
    {
      "address_link": "https://maps.app.goo.gl/czAHeW6LgDUaBNwo7",
      "address_text": "",
      "buttonLink": "",
      "buttonText": "",
      "collectionId": "1c25wgisimx84h8",
      "collectionName": "profiles",
      "created": "2024-08-08 10:00:03.030Z",
      "description": "Diplômée en 2008 de la Haute École de Santé (HES), je suis Diététicienne, membre de l’Association Suisse des Diététicien(ne)s diplômé(e)s (ASDD) au Centre Métabolique Nutrition et Mouvement à Carouge.\r\n\r\nMon approche est globale et personnalisée. Je prends en compte l'environnement familial, la culture, les expériences de vie, le cadre de travail, et les sensations digestives de mes patients pour leur offrir des conseils adaptés et durables. En utilisant des techniques d’entretien motivationnel, je soutiens les motivations de chacun pour les aider à persévérer. Mon approche cognitivo-comportementale permet d'identifier et de travailler sur les déclencheurs des comportements alimentaires. Je crois fermement que l'acte alimentaire reflète l'éducation, les principes, la culture et les expériences de vie de chacun.\r\n\r\nPassionnée de cuisine italienne, j'adore partager des repas conviviaux avec mes proches. Je pratique également la course à pied et le fitness. Je peux mener des consultations en français et en anglais.",
      "email": "deborah.haesslein@gmail.com",
      "first_name": "Déborah",
      "hidden": false,
      "id": "r5z21ddsmu62w45",
      "last_name": "Haesslein",
      "phone_number": "0223424419",
      "podcastId": "",
      "profile_picture": "",
      "specialty": [],
      "title": "",
      "updated": "2024-08-08 10:01:13.923Z",
      "website": "https://www.centremetabolique.ch/"
    }
  ],
  "articles": [
    {
      "Subtitle": "Salut subtitle",
      "Title": "Testing title",
      "collectionId": "0o8c76mnxmyqrny",
      "collectionName": "articles",
      "created": "2024-08-02 12:18:31.265Z",
      "description": "<p>Titre:&pound;<br><br><br><strong>Sakut</strong></p>",
      "id": "m8vw93exbkee5ye",
      "picture": "",
      "updated": "2024-08-02 12:18:31.265Z"
    }
  ]
}
