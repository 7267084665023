import {
  AlimentsResponse,
  ArticlesResponse,
  ProfilesResponse,
  RecettesResponse,
  UsersResponse,
} from '@pocketbase-types';
import { InjectionToken } from '@angular/core';

export const DATA_PROVIDER = new InjectionToken<DataProvider>('DataProvider');

export interface Data {
  articles: ArticlesResponse[];
  aliments: AlimentsResponse[];
  profiles: ProfilesResponse[];
  recettes: RecettesResponse[];
  users: UsersResponse[];
}

export interface DataProvider {
  getData(): Promise<Data>;
}
