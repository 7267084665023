import { Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { MissionBubbleCardComponent } from './mission-bubble-card.component';

@Component({
  selector: 'app-genese-bubble',
  standalone: true,
  imports: [NgOptimizedImage, MissionBubbleCardComponent],
  template: `
    <div class="flex px-[10vw]">
      <app-mission-bubble-card>
        <h1 class="-mt-8 self-start font-cupcakes text-4xl md:self-auto">
          GENESE
        </h1>
        <div class="flex items-center space-x-4 py-2 md:px-6">
          <!-- Desktop Elise -->
          <img
            class="absolute -left-32 bottom-0 hidden w-48 md:block"
            alt="Portrait d'Elise"
            ngSrc="/gutup/podcast/podcast1/podcast-portrait-elise.png"
            height="924"
            width="1028"
          />
          <div class="py-4 md:pl-4">
            <span>
              GutUp est né de mon expérience personnelle. Confrontée à des
              problèmes de santé, j'ai été poussée à rééquilibrer mon
              alimentation et j'ai découvert l'importance cruciale du microbiote
              intestinal. Après de nombreuses recherches, j'ai compris que
              beaucoup de maladies mentales et physiques prennent racine dans
              l'intestin.
              <br />
              <br />
              Animée par la volonté de partager cette révélation, je me suis
              entourée de professionnels de la santé et je me suis appuyée sur
              les dernières études médicales. Mon objectif est clair: aider le
              plus grand nombre à retrouver une santé optimale pour croquer la
              vie à pleines dents!
            </span>
          </div>
        </div>
      </app-mission-bubble-card>
    </div>
    <!-- Mobile Elise -->
    <div class="-mt-8 flex justify-center">
      <img
        class="flex w-48 md:hidden"
        alt="Portrait d'Elise"
        ngSrc="/gutup/podcast/podcast1/podcast-portrait-elise.png"
        height="924"
        width="1028"
      />
    </div>
  `,
})
export class GeneseBubbleComponent {}
