import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Profile } from './profile';

@Component({
  standalone: true,
  imports: [CommonModule],
  template: `
    @if (profile) {
      <h3>{{ profile.first_name }}</h3>
      <p>Email: {{ profile.email }}</p>
    }
  `,
})
export class ProfileComponent {
  profile: Profile = inject(ActivatedRoute).snapshot.data['profile'];
}
