import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SocialIconComponent } from '../social-icon/social-icon.component';
import { RoutingService } from '../../services/routing.service';

@Component({
  selector: 'app-gutup-footer',
  standalone: true,
  imports: [RouterLink, SocialIconComponent],
  template: `
    <footer>
      <div
        class="bg-gutup-footer h-24 bg-footer-bottom bg-cover bg-no-repeat md:hidden"
      ></div>
      <div
        class="bg-gutup-footer hidden h-44 bg-footer-bottom-desktop bg-cover bg-no-repeat md:block"
      ></div>

      <div
        class="flex flex-col bg-gutup-footer-dark px-[2vh] md:flex-row md:px-[10vh] md:pb-24"
      >
        <div class="flex md:w-2/3" routerLink="/">
          <img
            class="cursor-pointer"
            src="/gutup/footer/footer-logo.svg"
            alt="Gutup logo"
          />
        </div>

        <div
          class="flex flex-col text-center text-white md:w-1/2 md:flex-row md:text-left"
        >
          <div class="flex flex-col space-y-3 md:flex-grow">
            <div (click)="navigate('/mission')" class="gutup-footer-button">
              MISSION
            </div>
            <div (click)="navigate('/podcast')" class="gutup-footer-button">
              PODCAST
            </div>
            <div (click)="navigate('/contact')" class="gutup-footer-button">
              CONTACT
            </div>
            <div
              (click)="navigate('/legal-mentions')"
              class="gutup-footer-button"
            >
              MENTIONS LEGALES
            </div>
            <!--            <div routerLink="/privacy-policy" class="gutup-footer-button">POLITIQUE DE CONFIDENTIALITE</div>-->
          </div>
          <div class="flex flex-col space-y-4 pt-12 text-center">
            <div class="gutup-header-button-no-hover">REJOINS-NOUS</div>
            <div class="flex space-x-8 self-center">
              <app-social-icon
                [src]="'/social/insta.svg'"
                [link]="'https://www.instagram.com/gutup_foodie/'"
              />
              <app-social-icon
                [src]="'/social/youtube.svg'"
                [link]="'https://www.youtube.com/@gutup_foodie'"
              />
              <app-social-icon
                [src]="'/social/lkdn.svg'"
                [link]="'https://www.linkedin.com/company/gutup/'"
              />
              <app-social-icon
                [src]="'/social/spotify.svg'"
                [link]="'https://open.spotify.com/show/3oLhLQFNBUadCvciSze0PZ'"
              />
              <!--              <app-social-icon-->
              <!--                [src]="'/social/tiktok.svg'"-->
              <!--                [link]="'https://www.tiktok.com/@gutup_foodie'"-->
              <!--              />-->
            </div>
          </div>
        </div>
      </div>

      <div
        class="bg-gutup-footer-dark pb-10 pt-12 text-center font-extralight text-white md:pb-3 md:pr-[10vh] md:pt-0 md:text-end"
      >
        <span class="opacity-80">© 2024 GutUp </span>
      </div>
    </footer>
  `,
})
export class GutupFooterComponent {
  #routingService = inject(RoutingService);

  navigate(route: string) {
    this.#routingService.navigate([route], { scrollTop: true });
  }
}
