import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { Profile } from './profile';
import { DataService } from '../../services/data.service';

export const profileResolver: ResolveFn<Profile | undefined> = (route) => {
  const id = route.paramMap.get('id')!;
  return inject(DataService)
    .getProfiles()
    .find((i) => i.id === id);
};
