import { Component } from '@angular/core';
import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericEbookModalComponent } from './generic-ebook-modal.component';

@Component({
  selector: 'app-mobile-ebook-modal',
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass, NgOptimizedImage],
  template: `
    <div
      class="fixed inset-0 z-10 bg-black bg-opacity-50"
      (click)="closeModal()"
    ></div>
    <div
      class="fixed inset-0 z-20 flex items-center justify-center p-4"
      (click)="$event.stopPropagation()"
    >
      <button (click)="closeModal()" class="fixed right-4 top-4 z-30 p-2">
        <img
          ngSrc="/gutup/ebook/cross.png"
          alt="Close"
          class="h-6 w-6"
          height="21"
          width="19"
        />
      </button>

      <div
        class="relative h-full w-full space-y-12 overflow-y-auto rounded-lg bg-gutup-white shadow-md"
      >
        <div class="flex">
          <div class="relative flex flex-col">
            <img
              ngSrc="/gutup/ebook/mobile/ebook-header.png"
              alt="ebook"
              class="p-4 pt-12"
              width="1240"
              height="520"
            />
            <img
              ngSrc="/gutup/ebook/mobile/format-dispo.png"
              alt="ebook"
              class="p-4"
              width="1312"
              height="763"
            />
            <div class="relative">
              <img
                ngSrc="/gutup/ebook/mobile/first-form-container.png"
                alt="background"
                class=""
                width="1184"
                height="1356"
              />
            </div>
            <div class="relative -mt-44 flex flex-col self-center px-16">
              <form
                [formGroup]="formEbook"
                (ngSubmit)="submitEbook()"
                class="-mb-2 space-y-2"
              >
                <div class="w-full space-y-2">
                  <input
                    type="text"
                    formControlName="firstname"
                    id="firstname"
                    name="firstname"
                    placeholder="prénom"
                    class="w-full rounded-full py-1 text-center text-sm placeholder-gutup-orange shadow-md"
                    [ngClass]="{
                      'border-red-500': showError(formEbook, 'firstname'),
                    }"
                  />
                  @if (showError(formEbook, 'firstname')) {
                    <div class="mt-1 text-xs text-red-500">Prénom requis</div>
                  }
                  <input
                    type="email"
                    formControlName="email"
                    id="email"
                    name="email1"
                    placeholder="email"
                    class="w-full rounded-full py-1 text-center text-sm placeholder-gutup-orange shadow-md"
                    [ngClass]="{
                      'border-red-500': showError(formEbook, 'email'),
                    }"
                  />
                  @if (showError(formEbook, 'email')) {
                    <div class="mt-1 text-xs text-red-500">
                      {{ getEmailErrorMessage(formEbook) }}
                    </div>
                  }
                </div>

                <button
                  type="submit"
                  class="w-full rounded-xl bg-gutup-orange p-2 font-vinasans text-3xl text-white shadow-md"
                >
                  {{
                    isEbookSubmitting
                      ? 'Envoi en cours...'
                      : 'Je veux mon ebook!'
                  }}
                </button>
              </form>
              @if (submitErrorEbook) {
                <div class="mt-2 text-sm text-red-500">
                  Une erreur est survenue. Veuillez réessayer.
                </div>
              }
              @if (submitSuccessEbook) {
                <div class="mt-2 text-sm text-green-500">
                  Votre demande a été envoyée avec succès!
                </div>
              }
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="relative flex flex-col">
            <div class="relative">
              <img
                ngSrc="/gutup/ebook/mobile/real-book.png"
                alt="background"
                width="1320"
                height="1335"
              />

              <div class="bottom-8 -mt-44 flex flex-col self-center px-12">
                <form
                  [formGroup]="formPaperEbook"
                  (ngSubmit)="submitPaperEbook()"
                  class="space-y-2"
                >
                  <div class="w-full space-y-2">
                    <input
                      type="text"
                      formControlName="firstname"
                      id="firstname"
                      name="firstname"
                      placeholder="prénom"
                      class="w-full rounded-full py-1 text-center text-sm placeholder-gutup-orange shadow-md"
                      [ngClass]="{
                        'border-red-500': showError(
                          formPaperEbook,
                          'firstname'
                        ),
                      }"
                    />
                    @if (showError(formPaperEbook, 'firstname')) {
                      <div class="mt-1 text-xs text-red-500">Prénom requis</div>
                    }
                    <input
                      type="email"
                      formControlName="email"
                      id="email"
                      name="email1"
                      placeholder="email"
                      class="w-full rounded-full py-1 text-center text-sm placeholder-gutup-orange shadow-md"
                      [ngClass]="{
                        'border-red-500': showError(formPaperEbook, 'email'),
                      }"
                    />
                    @if (showError(formPaperEbook, 'email')) {
                      <div class="mt-1 text-xs text-red-500">
                        {{ getEmailErrorMessage(formPaperEbook) }}
                      </div>
                    }
                  </div>

                  <button
                    type="submit"
                    class="w-full rounded-xl bg-gutup-orange p-2 font-vinasans text-2xl text-white shadow-md"
                  >
                    {{
                      isEbookPaperSubmitting
                        ? 'Envoi en cours...'
                        : 'Je precommande mon livre!'
                    }}
                  </button>
                </form>
                @if (submitErrorPaperEbook) {
                  <div class="mt-2 text-sm text-red-500">
                    Une erreur est survenue. Veuillez réessayer.
                  </div>
                }
                @if (submitSuccessPaperEbook) {
                  <div class="mt-2 text-sm text-green-500">
                    Votre demande a été envoyée avec succès!
                  </div>
                }
              </div>
            </div>

            <div class="p-4 pt-12">
              <img
                ngSrc="/gutup/ebook/mobile/ebook-footer.png"
                alt="background"
                width="1240"
                height="1341"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class MobileEbookModalComponent extends GenericEbookModalComponent {}
