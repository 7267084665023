import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GutupBannerComponent } from '../../components/gutup-banner/gutup-banner.component';
import FreeResourceFormComponent from '../../components/free-resource-form.component';
import { NgOptimizedImage } from '@angular/common';
import { BubbleCardComponent } from '../../components/bubble-card.component';
import { MissionBubbleCardComponent } from './components/mission-bubble-card.component';
import { HippocrateBubbleComponent } from './components/hippocrate-bubble.component';
import { ConstatBubbleComponent } from './components/constat-bubble.component';
import { GeneseBubbleComponent } from './components/genese-bubble.component';
import { MissionBubbleComponent } from './components/mission-bubble.component';
import { LogoExplainationComponent } from './components/logo-explaination.component';

@Component({
  selector: 'app-gutup-mission',
  standalone: true,
  imports: [
    GutupBannerComponent,
    BubbleCardComponent,
    FreeResourceFormComponent,
    NgOptimizedImage,
    BubbleCardComponent,
    BubbleCardComponent,
    MissionBubbleCardComponent,
    HippocrateBubbleComponent,
    ConstatBubbleComponent,
    GeneseBubbleComponent,
    MissionBubbleComponent,
    LogoExplainationComponent,
  ],
  template: `
    <div class="space-y-24 md:space-y-32">
      <div class="md:px-[10vw]">
        <app-hippocrate-bubble />
      </div>

      <div class="space-y-24">
        <!-- Container -->
        <div class="flex flex-col space-y-24 md:space-y-32">
          <div class="md:w-3/4 md:self-end md:pr-24">
            <app-constat-bubble />
          </div>

          <div class="md:pl-12">
            <app-genese-bubble />
          </div>

          <div class="md:pr-24 md:pt-16">
            <app-mission-bubble />
          </div>

          <div>
            <app-logo-explaination />
          </div>
        </div>

        <div class="pt-12 md:pt-0">
          <app-bubble-card
            class="hidden md:block"
            [hasTitle]="true"
            [backgroundName]="'bg-green-bubble-desktop'"
          >
            <app-free-resource-hubspot-form class="w-full" />
          </app-bubble-card>

          <app-bubble-card
            class="md:hidden"
            [hasTitle]="true"
            [backgroundName]="'bg-green-bubble'"
          >
            <app-free-resource-hubspot-form class="w-full" />
          </app-bubble-card>
        </div>

        <!-- Spacer for gutup banner -->
        <div></div>
      </div>
      <app-gutup-banner />
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionComponent {}
