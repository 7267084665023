import {
  APP_INITIALIZER,
  ApplicationConfig,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { DATA_PROVIDER } from '../services/data-provider';
import { environment } from '../environments/environment';
import { ProdDataProvider } from '../services/prod-data-provider';
import { DevDataProvider } from '../services/dev-data-provider';
import { DataService } from '../services/data.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideClientHydration(),
    provideAngularSvgIcon(),
    provideHttpClient(withFetch()),
    {
      provide: DATA_PROVIDER,
      useFactory: dataServiceFactory,
    },
    DataService,
    {
      provide: APP_INITIALIZER,
      useFactory: (dataService: DataService) => initializeApp(dataService),
      deps: [DataService],
      multi: true,
    },
  ],
};

export function dataServiceFactory() {
  return environment.production
    ? new ProdDataProvider()
    : new DevDataProvider();
}

export function initializeApp(dataService: DataService): () => Promise<void> {
  return () => dataService.loadData();
}
