import { inject, Injectable } from '@angular/core';
import {
  AlimentsResponse,
  RecettesResponse,
  ProfilesResponse,
} from '@pocketbase-types';
import { Data, DATA_PROVIDER } from './data-provider';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private dataProvider = inject(DATA_PROVIDER);
  private data: Data | null = null;

  async loadData(): Promise<void> {
    try {
      this.data = await this.dataProvider.getData();
    } catch (error) {
      console.error('Error loading data:', error);
      this.data = null;
    }
  }

  getFoods(): AlimentsResponse[] {
    return (this.data?.aliments as AlimentsResponse[]) ?? [];
  }

  getRecipes(): RecettesResponse[] {
    return (this.data?.recettes as RecettesResponse[]) ?? [];
  }

  getProfiles(): ProfilesResponse[] {
    return (this.data?.profiles as ProfilesResponse[]) ?? [];
  }
}
