import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-profiles',
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: `
    <h2>Profiles</h2>
    <ul>
      @for (profile of profiles; track profile.id) {
        <li>
          <a [routerLink]="['/profiles', profile.id]">{{
            profile.first_name
          }}</a>
        </li>
      }
    </ul>
  `,
})
export class ProfileListComponent {
  profiles = inject(DataService).getProfiles();
}
