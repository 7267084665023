import { Component, Input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { BubbleCardComponent } from '../../../components/bubble-card.component';
import { Speaker } from '../../../models/speaker';

@Component({
  selector: 'app-podcast-speaker',
  standalone: true,
  imports: [NgOptimizedImage, BubbleCardComponent],
  template: `
    @if (speaker.bio.length > 600) {
      <div class="flex flex-col space-y-6 md:space-y-0">
        <div class="justify-center md:flex">
          <div
            class="hidden flex-col items-end md:flex md:w-1/2 md:min-w-[400px]"
          >
            <img
              [ngSrc]="speaker.imageUrl"
              [alt]="speaker.name"
              height="400"
              width="400"
            />
          </div>
          <div class="flex flex-col justify-center md:w-1/2">
            <!--  Portrait (mobile only) -->
            <div class="flex justify-center md:hidden">
              <img
                [ngSrc]="speaker.imageUrl"
                [alt]="speaker.name"
                height="500"
                width="500"
              />
            </div>

            <div class="-mt-6 space-y-2 text-center md:text-left">
              <div class="gutup-title relative flex flex-col">
                {{ speaker.name }}
              </div>
              <div class="text-base md:text-2xl">
                {{ speaker.title }}
              </div>
              @if (speaker.buttonText) {
                <a [href]="speaker.buttonLink" target="_blank">
                  <button class="gutup-button mt-4 w-56">
                    {{ speaker.buttonText }}
                  </button>
                </a>
              }
            </div>
            <div class="flex justify-center pb-2 md:hidden">
              @if (speaker.buttonText) {
                <a [href]="speaker.buttonLink" target="_blank">
                  <button class="gutup-button w-56">
                    {{ speaker.buttonText }}
                  </button>
                </a>
              }
            </div>
          </div>
        </div>
        <div class="max-w-[1154px]">
          <app-bubble-card>
            <div [innerHTML]="speaker.bio"></div>
          </app-bubble-card>
        </div>
      </div>
    } @else {
      <div class="flex flex-col space-y-12 md:flex-row">
        <div class="md:w-1/2 md:min-w-[400px]">
          <div class="hidden items-end md:flex md:flex-col">
            <div class="flex flex-col items-center justify-center">
              <img
                [ngSrc]="speaker.imageUrl"
                [alt]="speaker.name"
                width="300"
                height="300"
              />
              <!-- TODO Alexy Discuss sizes with Elise -->
              @if (speaker.buttonText) {
                <a [href]="speaker.buttonLink" target="_blank">
                  <button class="gutup-button w-56">
                    {{ speaker.buttonText }}
                  </button>
                </a>
              }
            </div>
          </div>
        </div>
        <div class="space-y-2 text-left md:w-1/2">
          <div class="flex justify-center md:hidden">
            <img [src]="speaker.imageUrl" [alt]="speaker.name" />
          </div>
          <div
            class="gutup-title relative flex flex-col text-center md:text-left"
          >
            {{ speaker.name }}
          </div>
          <div class="text-center text-base md:text-left md:text-2xl">
            {{ speaker.title }}
          </div>
          <div class="flex justify-center pb-2 md:hidden">
            @if (speaker.buttonText) {
              <a [href]="speaker.buttonLink" target="_blank">
                <button class="gutup-button w-56">
                  {{ speaker.buttonText }}
                </button>
              </a>
            }
          </div>
          <div class="max-w-[1154px]">
            <app-bubble-card>
              <div [innerHTML]="speaker.bio"></div>
            </app-bubble-card>
          </div>
        </div>
      </div>
    }
  `,
})
export class PodcastSpeakerComponent {
  @Input({ required: true }) speaker!: Speaker;
}
