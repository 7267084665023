import {
  Component,
  EventEmitter,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { DOCUMENT, NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BubbleCardComponent } from './bubble-card.component';
import { MobileEbookModalComponent } from './ebook-modal/mobile-ebook-modal.component';
import { DesktopEbookModalComponent } from './ebook-modal/desktop-ebook-modal.component';

@Component({
  selector: 'app-gutup-ebook-modal',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    BubbleCardComponent,
    NgOptimizedImage,
    ReactiveFormsModule,
    NgClass,
    MobileEbookModalComponent,
    DesktopEbookModalComponent,
  ],
  template: `
    <app-mobile-ebook-modal (closeEvent)="closeModal()" class="md:hidden" />
    <app-desktop-ebook-modal
      (closeEvent)="closeModal()"
      class="hidden md:block"
    />
  `,
  styles: [],
})
export class EbookModalComponent implements OnInit, OnDestroy {
  @Output() closeEvent = new EventEmitter<void>();

  @HostListener('document:keydown.escape', ['$event'])
  handleEscapeKey() {
    this.closeModal();
  }

  #document = inject(DOCUMENT);
  #renderer = inject(Renderer2);

  ngOnInit() {
    this.#renderer.addClass(this.#document.body, 'overflow-hidden');
  }

  ngOnDestroy() {
    this.#renderer.removeClass(this.#document.body, 'overflow-hidden');
  }

  closeModal() {
    this.closeEvent.emit();
  }
}
