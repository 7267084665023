import { Routes } from '@angular/router';
import { HomeComponent } from '../pages/home/home.component';
import { PageNotFoundComponent } from '../pages/page-not-found/page-not-found.component';
import { PodcastComponent } from '../pages/podcast/podcast.component';
import { ContactComponent } from '../pages/contact/contact.component';
import { PodcastDetailComponent } from '../pages/podcast/podcast-detail/podcast-detail.component';
import { InstafluxComponent } from '../pages/instaflux/instaflux.component';
import { isDevMode } from '@angular/core';
import { LegalMentionsComponent } from '../pages/legal/legal-mentions.component';
import { RecipeListComponent } from '../pages/recipes/recipe-list.component';
import { RecipeComponent } from '../pages/recipe/recipe.component';
import { recipeResolver } from '../pages/recipe/recipe-resolver';
import { FoodListComponent } from '../pages/food-list/food-list.component';
import { FoodComponent } from '../pages/food/food.component';
import { foodResolver } from '../pages/food/food-resolver';
import { ProfileListComponent } from '../pages/profiles/profiles.component';
import { ProfileComponent } from '../pages/profile/profile.component';
import { profileResolver } from '../pages/profile/profile.resolver';
import { podcastDetailResolver } from '../pages/podcast/podcast-detail/podcast-detail-resolver';
import { MissionComponent } from '../pages/mission/mission.component';
import { NosSourcesComponent } from '../pages/nos-sources.component';

export const devRoutes: Routes = [
  { path: 'profiles', component: ProfileListComponent },
  {
    path: 'profiles/:id',
    component: ProfileComponent,
    resolve: { profile: profileResolver },
  },
  { path: 'recettes', component: RecipeListComponent },
  {
    path: 'recettes/:id',
    component: RecipeComponent,
    resolve: { recipe: recipeResolver },
  },
  { path: 'aliments', component: FoodListComponent },
  {
    path: 'aliments/:id',
    component: FoodComponent,
    resolve: { data: foodResolver },
  },
  { path: 'insta', component: InstafluxComponent },
];

export const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'home', redirectTo: '' },
  { path: 'mission', component: MissionComponent },
  { path: 'podcast', component: PodcastComponent },
  { path: 'nos-sources', component: NosSourcesComponent },
  {
    path: 'podcast/:id',
    component: PodcastDetailComponent,
    resolve: { podcastDetail: podcastDetailResolver },
  },
  { path: 'contact', component: ContactComponent },
  { path: 'legal-mentions', component: LegalMentionsComponent },
  { path: 'privacy-policy', component: PageNotFoundComponent },
  { path: '404', component: PageNotFoundComponent },
  ...(isDevMode() ? devRoutes : []),
  { path: '**', component: PageNotFoundComponent },
];
